import $ from 'jquery';
import 'slick-carousel';

$(function(){

    /*--------------------------------------------------------------
    ## Page Home - Banner
    --------------------------------------------------------------*/
    // const checkHeight = () => {
    //     const currentSlide = $('.js-home-banner .slick-current h1');
    //     const slideHeight = currentSlide.outerHeight();
    //     $(".js-banner-dots .slick-dots").attr({
    //         'style': `top: ${slideHeight}px`
    //     });
    // };
    // $(".js-home-banner").on('init', checkHeight).on('setPosition', checkHeight);
    // $(window).on('resize', checkHeight);
 
    $(".js-home-banner").slick({ 
        dots: true,
        arrows: false,
        appendDots: $(".js-banner-dots"),
        autoplay: true,
        autoplaySpeed: 6000,
    });

    /*--------------------------------------------------------------
    ## Page Home - QuickAccess
    --------------------------------------------------------------*/
    $(".js-home-quickaccess").slick({
        dots: false,
        arrows: false, 
        infinite: true,
        slidesToShow: 5,
        responsive: [  
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });

    /*--------------------------------------------------------------
    ## Cotação - Live update
    --------------------------------------------------------------*/
    if (document.querySelector(".js-quotes")) {
        var MZStockInfo = new MZIQ_StockInfo();

        var decimalConfig = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        var decimalConfigVol = {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        };

        var options = {
            language: langCodeFormatted,
            stockinfoId: COMPANIES[0].id_stockinfo,
            template: {
                type: "raw",
            },
            rawInit: function (data) {
                var tickers = data.tickers.concat(data.indexes);
                var TICKER = tickers[0];
                var IBOV = tickers[1];

                // var date = TICKER.info.date.substr(-5).replace("-", "/");
                // var time = TICKER.info.time.substr(0, 5);
                // var fullTime = date + " " + time + " delay 15 min.";
                // $(".quotes__update span").html(fullTime);

                function generateQuotes(className, dataName) {
                    $(`${className}.name`).html(dataName.ticker);
                    $(`${className}.price`).html("R$ " + dataName.info.price.toLocaleString(lang));
                    $(`${className}.variation`).html(dataName.info.var.toLocaleString(lang, decimalConfig) + "%");

                    let variation = dataName.info.var > 0 ? "up" : "down";
                    $(`${className}.variation`).addClass(variation);
                }

                generateQuotes(".TICKER", TICKER);
                generateQuotes(".INDEX", IBOV);
            },
        };
        MZStockInfo.initialize(options);
    }

})